import React from 'react';
import { format } from 'date-fns';

type VerifyInfoProps = {
    options: any[];
    inputValues: { [key: string]: string };
    setVerificationResults: React.Dispatch<React.SetStateAction<string[]>>;
    toggleVerificationPopup: () => void;
    selectedDates: Record<string, Date | undefined>;
  };
  
  const verifyInfo = ({
    options,
    inputValues,
    setVerificationResults,
    toggleVerificationPopup,
    selectedDates,
  }: VerifyInfoProps) => {
    const results: string[] = [];
  
    options.forEach((option) => {
      const value = inputValues[option.id];
      const dataType = option.dataType.toLowerCase() === 'int' ? 'number' 
      : option.dataType.toLowerCase() === 'bit' ? 'boolean' 
      : option.dataType.toLowerCase() === 'varchar' ? 'string' 
      : option.dataType.toLowerCase() === 'date' ? 'datetime' 
      : option.dataType; // Convert to lowercase for easier comparison
  
      // Convert the value to the expected data type for comparison
      let convertedValue: any;
      switch (dataType) {
        case 'string':
          if (value === undefined || value === null || value === '') {
            results.push(`Parameter ${option.parameterName} "${value}" is invalid. Expected ${dataType}.`);
            return;
          } else{
          convertedValue = String(value);
          }
          break;
        case 'number':
          // Check if the value is a valid number
          if (!isNaN(Number(value)) && value !== undefined && value !== null && value !== '') {
            convertedValue = parseInt(value, 10);
          } else {
            results.push(`Parameter ${option.parameterName} "${value}" is invalid. Expected ${dataType}.`);
          }
          break;
        case 'boolean':
          // Check if the value is a valid boolean
          if (value === 'true' || value === 'false' || value === '1' || value === '0') {
            convertedValue = value === 'true' || value === '1';
          } else {
            results.push(`Parameter ${option.parameterName} "${value}" is invalid. Expected ${dataType}.`);
          }
          break;
        case 'datetime':
          const date = new Date(value);
          // Check if the value is a valid date
          if (selectedDates) {
            results.push(`Parameter ${option.parameterName} "${format(selectedDates[option.id] as Date, 'MM-dd-yyyy')}" is valid.`);
          } else if (!isNaN(date.getTime())) {
            convertedValue = date;
          } else {
            results.push(`Parameter ${option.parameterName} "${value}" is invalid. Expected ${dataType}.`);
          }
          break;
        default:
          console.error(`Unknown data type: ${dataType}`);
          convertedValue = value; // Assign a default value if data type is unknown
      }
  
      // Perform the comparison using the converted value
      if (typeof convertedValue === dataType) {
        results.push(`Parameter ${option.parameterName} "${convertedValue}" is valid.`);
      } else if (typeof convertedValue === 'object' && convertedValue instanceof Date) {
        results.push(`Parameter ${option.parameterName} "${convertedValue.toLocaleString()}" is valid.`);
      }else {
        return;
      }
    });
  
    // Set verification results
    setVerificationResults(results);
    toggleVerificationPopup(); // Show the verification popup
  };
  
  export default verifyInfo;  