import React, { useEffect, useState } from 'react';
import '../styles/ProcedureList.css';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { msalInstance } from '../index';
import { loginRequest } from '../auth/AuthConfig';

interface Props {
  selectProcedure: (procedure: string) => void;
}

const ProcedureList: React.FC<Props> = ({ selectProcedure }) => {
  const [procedures, setProcedures] = useState<string[]>([]);
  const [filteredProcedures, setFilteredProcedures] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const endURL = '/api/Procedure/GetAllProceduresAsync';
  const baseURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const request = loginRequest; 
    const a = "Authorization";

    msalInstance.acquireTokenSilent(request).then(tokenResponse => {
      let bearer = "Bearer " + tokenResponse.accessToken;

    const headers = new Headers();
    headers.append(a,bearer);
    const options = {
            method: "GET",
            headers: headers
    };
    const fetchProcedureNames = async () => {
      await fetch(baseURL+endURL, options)
        .then((res) => res.json())
        .then((data) => {
          if (data != undefined) {
            const procedureNames = data.map(
              (procedure: { procedureName: string }) => procedure.procedureName
            );
            setProcedures(procedureNames);
            setFilteredProcedures(procedureNames);
          } else {
            console.error('No procedures found in the response data.');
          }
        })
        .catch((error) => console.error('Failed to fetch procedures:', error));
    };

    fetchProcedureNames();
  });
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = procedures.filter((procedure) =>
        procedure.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProcedures(filtered);
    } else {
      setFilteredProcedures(procedures);
    }
  }, [searchTerm, procedures]);

  const handleProcedureSelection = (procedure: string) => {
    selectProcedure(procedure);
    navigate({
      pathname: '/ProcedureDetails',
      search: '?name=' + procedure,
    });
  };

  return (
    <Container>
      <Row>
        <Col md={6}>
          <Form>
            <Form.Control
              className="search-box"
              type="text"
              placeholder="Search procedures"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form>

          <ul className="procedure-list">
            {filteredProcedures.map((procedure) => (
              <li
                className="li"
                key={procedure}
                onClick={() => handleProcedureSelection(procedure)}
              >
                {procedure}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default ProcedureList;