import React, { useState } from 'react';
import { useIsAuthenticated } from "@azure/msal-react";
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import benesysLogo from '../../assets/images/benesys-logo.png';  
import loginImage from '../../assets/images/login-img.svg'; 
import '../../styles/Login.css';


const Login: React.FC = () => {
  const history = useNavigate();

  const handleLogin = () => {
      history("/dashboard");  // Redirect to the dashboard
    };

  return (
    // implement a page title for the browser tab
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <img src={benesysLogo} alt='BeneSys Logo' className='benesys-logo'/>
      <Container className="login-container mt-5">
        <title>Login</title>
        <h3 className='login-txt'>Sign in to the Benefit Driven Utility</h3>
        <Form>
          <Button className='login-btn' variant="primary" onClick={handleLogin}>
            Login
          </Button>
        </Form>
        <img src={loginImage} alt="Login Image" className="login-image" />
      </Container>
    </>
  );
};

export default Login;