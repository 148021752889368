import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";


// Import your components
import Login from './pages/auth/Login';
import Dashboard from './pages/Dashboard';
import ProcedureDetails from './pages/ProcedureDetails'; // Import the new component

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  return (
      <MsalProvider instance={pca}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/ProcedureDetails" element={<ProcedureDetails />} />
        </Routes>
      </MsalProvider>
  );
}

export default App;