import React, { useState, } from 'react';
import ProcedureList from '../components/ProcedureList';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/dashboardStyles.css';
import Header from '../components/header';
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from '../auth/AuthConfig';

const Dashboard: React.FC = () => {
  const [, setSelectedProcedure] = useState<any | null>(null);

  const selectProcedure = (procedure: any) => {
    procedure = procedure.replace(/\s/g, '');
    setSelectedProcedure(procedure);
    // Pass the selected procedure to ProcedureDetails
  };

  return (
    <MsalAuthenticationTemplate 
    interactionType={InteractionType.Redirect}
    authenticationRequest={loginRequest}
    >
      <Container fluid className="full-height-form">
        <Helmet>
          <title>Procedure Dashboard</title>
        </Helmet>
        <Header />
        <h1 className='h1'>Procedure Dashboard</h1>

        {/* Add SearchBox component */}
        <Row className="options-list">
          <Col md={6}>
            <ProcedureList selectProcedure={selectProcedure} />
          </Col>
        </Row>
      </Container>
    </MsalAuthenticationTemplate>
  );
};

export default Dashboard;